import Highlighter from "react-highlight-words"
import React from "react"
import parse from "html-react-parser"
import striptags from "striptags"

import { SectionHeader } from "./titleSection.style"

const Heading = ({ headingTag, children }) => {
  return (
    <>
      {headingTag === "h1" ? (
        <h2>{children}</h2>
      ) : headingTag === "h2" ? (
        <h2>{children}</h2>
      ) : (
        <h3>{children}</h3>
      )}
    </>
  )
}

const TitleSection = ({
  titleA,
  titleB,
  subtitle,
  reverseColor,
  subTitleMaxWidth,
  titleMaxWidth,
  marginBottom,
  addBreak,
}) => {
  return (
    <SectionHeader
      reverseColor={reverseColor}
      subTitleMaxWidth={subTitleMaxWidth}
      titleMaxWidth={titleMaxWidth}
      marginBottom={marginBottom}
    >
      <Heading headingTag={titleA.substring(1, 3)}>
        {!addBreak ? (
          <Highlighter
            highlightClassName="highlightClassMain"
            searchWords={[`${parse(striptags(titleB))}`]}
            autoEscape={true}
            textToHighlight={`${parse(striptags(titleA))} ${parse(
              striptags(titleB)
            )}`}
          />
        ) : (
          <Highlighter
            highlightClassName="highlightClassAlt"
            searchWords={[`${parse(striptags(titleB))}`]}
            autoEscape={true}
            textToHighlight={`${parse(striptags(titleA))} ${parse(
              striptags(titleB)
            )}`}
          />
        )}
      </Heading>
      <div dangerouslySetInnerHTML={{ __html: subtitle }} />
    </SectionHeader>
  )
}

export default TitleSection
