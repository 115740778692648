import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

export const SectionHeader = styled.header`
  text-align: left;
  margin-bottom: ${props => (props.marginBottom ? "20px" : "28px")};
  max-width: 1300px;
  @media only screen and (max-width: 990px) {
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 480px) {
    margin-bottom: 24px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0px;
    max-width: ${props =>
      props.titleMaxWidth ? props.titleMaxWidth : "800px"};
    @media only screen and (max-width: 480px) {
      line-height: 36px;
    }
  }

  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 34px;
  }
  h3 {
    font-size: 28px;
  }

  .highlightClassMain {
    color: ${themeGet("colors.mainBlue")};
    background: transparent;
  }
  .highlightClassAlt {
    display: block;
    color: ${themeGet("colors.mainBlue")};
    background: transparent;
    @media only screen and (max-width: 1200px) {
      display: contents;
    }
    @media only screen and (max-width: 991px) {
      display: contents;
    }
    @media only screen and (max-width: 480px) {
      display: contents;
    }
  }

  .titleB {
    color: ${themeGet("colors.mainBlue")};
    white-space: nowrap;
  }

  span {
    font-weight: 700;
    color: ${props => (props.reverseColor ? "#fff" : "#000")};
    @media only screen and (max-width: 990px) {
      font-size: 32px;
    }
    @media screen and (max-width: 480px) {
      font-size: 26px;
    }
  }

  p {
    font-weight: 300;
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 0;
    margin-top: 8px;
    max-width: 700px;
    color: ${props => (props.reverseColor ? "#fff" : "#000")};
    max-width: ${props =>
      props.subTitleMaxWidth ? props.subTitleMaxWidth : "700"};
    width: 100%;
    @media only screen and (max-width: 990px) {
      font-size: 22px;
      line-height: 28px;
      margin-top: 8px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 18px;
      line-height: 28px;
      margin-top: 4px;
    }
  }
`
